import { Button, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'

const EditTreeItem = ({tree,editTree}) => {
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const handleSubmit = () =>{
    if(accountName!=='' && accountType !== '' && accountNumber !== ''){
      editTree(accountName,accountType,accountNumber)
    }
    else{
      message.error("يجب ملئ جميع الإدخالات")
    }
  } 

  useEffect(()=>{
    if(tree){
      setAccountName(tree.ItemInfo.AccountName)
      setAccountNumber(tree.ItemInfo.AccountNumber)
      setAccountType(tree.ItemInfo.AccountType)
  
    }
  },[tree])
  
  return (
    <div className='py-4'>
      <div className='grid grid-cols-2 gap-4 mb-8'>
          <Input value={accountName} onChange={(e) => setAccountName(e.target.value)} placeholder='اسم الحساب' />
          <Input value={accountType} onChange={(e) => setAccountType(e.target.value)} placeholder='نوع الحساب' />
          <Input value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} placeholder='رقم الحساب' />
      </div>
      <Button onClick={handleSubmit} type='primary'>تعديل</Button>

  </div>
  )
}

export default EditTreeItem