import React, { useEffect, useState } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import TopNav from '../../Components/TopNav';
import { Button } from 'antd';
import logo from './../../Images/SignInStrings.png'

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import DynamicBreadcrumb from '../../Components/DynamicBreadcrumb';
import SideNav from '../../Components/SideNav';
import Documentation from '../Documentation';
import MyAccount from '../MyAccount';
import FinanceTress from '../FinanceTrees';
import { getSideNavItem } from '../../utils/DataManager/ManageSideNavItems';
import FinanceTree from '../FinanceTree';
const Main = ({permissions,user}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [linkList, setLinkList] = useState([]);
  const [sideNavItems, setSideNavItems] = useState([]);

  const addToMinimizeList = (lnk, text) => {
    setLinkList(prevList => {
      const linkExists = prevList.some(item => item.link === lnk);
      if (linkExists) {
        return prevList; 
      }
      return [
        ...prevList,
        {
          key: new Date().toISOString(),
          label: (<Link to={lnk}>{text}</Link>),
          link: lnk
        }
      ];
    });
  };

  const removeFromMinimizeList = (key) => {
    setLinkList(prevList => prevList.filter(item => item.key !== key));
  };

  const fetchSideNav = async () => {
    try {
      const fetchedItems = await getSideNavItem(permissions);
      setSideNavItems(fetchedItems);
    } catch (error) {
      console.error('Failed to fetch side nav items:', error);
    }
  };
  

  useEffect(() => {
    fetchSideNav();
  }, [permissions]);

  return (
    <main className='flex flex-row gap-4 h-[100vh]'>
      <div className={`${collapsed ? 'w-[4%]' : 'w-[15%]'} overflow-y-scroll transition-all duration-300 py-2`}>
        {collapsed ? (
          <div className='flex justify-center items-center'>
            <img className='w-10' src={logo} alt="Logo" />
          </div>
        ) : (
          <h1 className='p-3 font-semibold text-blue-800 text-lg border-b mx-1 mb-1'>
            ULDash <br /> <span className='text-sm text-blue-500'>Next Generation</span>
          </h1>
        )}
        <SideNav items={sideNavItems} permissions={permissions} collapsed={collapsed} />
        <div className='flex flex-row justify-center items-center'> 
          <Button onClick={() => setCollapsed(!collapsed)} icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} block className='bg-transparent' />
        </div>
      </div>
      <div className={`${collapsed ? 'w-[93%]' : 'w-[82%]'} transition-all duration-300 overflow-y-scroll`}>
        <TopNav user={user} linkList={linkList} removeFromMinimizeList={removeFromMinimizeList} />
        <section className='px-4'>
          <DynamicBreadcrumb />
        </section>
        <section className='p-6'>
          <Routes>
            <Route path="/My-Account" element={<MyAccount />} />
            <Route path="/Documentation" element={<Documentation />} />
            {permissions.FinancialSystem && (
              <>
                <Route path='/Finance-Trees' element={<FinanceTress showNothing={true} showAddModal={false} showEditModal={false} addToMinimizeList={addToMinimizeList} showDocumentation={false} fetchSideNav={fetchSideNav} />} />
                <Route path='/Finance-Trees/Documentation' element={<FinanceTress showNothing={true} showAddModal={false} showEditModal={false} addToMinimizeList={addToMinimizeList} showDocumentation={true} fetchSideNav={fetchSideNav} />} />
                <Route path='/Finance-Trees/Add' element={<FinanceTress showNothing={false} showAddModal={true} showEditModal={false} addToMinimizeList={addToMinimizeList} fetchSideNav={fetchSideNav} />} />
                <Route path='/Finance-Trees/Edit' element={<Navigate to={`/Finance-Trees`} />} />
                <Route path='/Finance-Tree' element={<Navigate to={`/Finance-Trees`} />} />
                <Route path='/Finance-Trees/Edit/:id' element={<FinanceTress showNothing={false} showAddModal={false} showEditModal={true} addToMinimizeList={addToMinimizeList} fetchSideNav={fetchSideNav} />} />
                <Route path='/Finance-Tree/:id' element={<FinanceTree showNothing={true} showAddModal={false} showEditModal={false} showDocumentation={false} addToMinimizeList={addToMinimizeList} />} />
                <Route path='/Finance-Tree/:id/Documentation' element={<FinanceTree showNothing={true} showAddModal={false} showEditModal={false} showDocumentation={true} addToMinimizeList={addToMinimizeList} />} />
                <Route path='/Finance-Tree/:id/Add' element={<FinanceTree showNothing={false} showAddModal={true} showEditModal={false} addToMinimizeList={addToMinimizeList} />} />
                <Route path='/Finance-Tree/:id/Edit/:acc_id' element={<FinanceTree showNothing={false} showAddModal={false} showEditModal={true} addToMinimizeList={addToMinimizeList} />} />

              </>
            )}
          </Routes>
        </section>
      </div>
    </main>
  );
}

export default Main;
