import { Button, Dropdown, Tooltip,Input } from 'antd'
import { InfoIcon, ListIcon, LogOutIcon, SearchIcon, UserIcon } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const TopNav = ({linkList,removeFromMinimizeList,user}) => {
    const handleMenuClick = (key) => {
        removeFromMinimizeList(key);
      }
    const items = linkList.map(item => ({
        key: item.key,
        label: (
          <span onClick={() => handleMenuClick(item.key)}>
            {item.label}
          </span>
        )
      }))

    const onSearch = () =>{

    }
  return (
    <section className='p-2  w-full flex flex-row  justify-between'>
    <h2 className='font-bold text-lg'><span className='text-blue-500 ml-2'>مرحبا</span>{user && user.MainInfo.FirstName}</h2>
    <div className='flex justify-around gap-4'>

            <Dropdown
                menu={{
                    items: items.map(item => ({
                      ...item,
                      onClick: handleMenuClick,
                    })),
                  }}
                
                placement="bottomLeft"
                arrow
            >
                <Button shape='circle' icon={<ListIcon size={16} />} />

            </Dropdown>
            
            <Tooltip title="حسابي">
                <Link to={`My-Account`}>
                     <Button icon={<UserIcon size={16} />} shape='circle' />
                </Link>
            </Tooltip>
            <Tooltip title="صفحة الدعم">
                <Link to={`Documentation`}>
                     <Button icon={<InfoIcon size={16} />} shape='circle' />
                </Link>
            </Tooltip>
            
            <Tooltip title="تسجيل الخروج">
                 <Button icon={<LogOutIcon size={16} />} shape='circle' />
            </Tooltip>
        </div>
   
</section>
  )
}

export default TopNav