import {
    DollarOutlined,
    AppstoreOutlined,
    UserSwitchOutlined,
    ImportOutlined,
    UsergroupDeleteOutlined
  } from '@ant-design/icons'
import { GetFinanceTree } from '../Api/Finance'
let items = [
    {
      key: 'FinancialSystem',
      label: 'النظام المالي',
      icon: <DollarOutlined />,
      children: [
        
      ]
    },
    {
        key: 'Warehouses',
        label: 'المستودعات',
        icon: <AppstoreOutlined />,
        children: [
        
        ]
    },
    {
        key: 'Customers',
        label: 'الزبائن',
        icon: <UserSwitchOutlined />,
        children: [
        
        ]
    },
    {
        key: 'Suppliers',
        label: 'الموردين',
        icon: <ImportOutlined />,
        children: [
        
        ]
    },
    {
        key: 'Employees',
        label: 'الموظفين',
        icon: <UsergroupDeleteOutlined />,
        children: [
        
        ]
    }
]

const setupFinancialKeys = async() =>{
    const trees = await GetFinanceTree()
    let childs = [{
        key: 'Fin',
        label: 'إدارة الشجرات',
        link:"/Finance-Trees"

    }]
    items.map((itm)=>{
        if(itm.key === 'FinancialSystem'){
            trees.map((tre,index)=>{
                childs.push({
                    key: 'Fin'+index,
                    label: tre.Name,
                    link:"/Finance-Tree/"+tre.id
                })
            })
            itm.children = childs
        }
    })
}

export const getSideNavItem = async(permissions) => {
    let side_nav_items = []
    await setupFinancialKeys()
    items.map((itm)=>{
        if(permissions[itm.key]){
            side_nav_items.push(itm)
        }
    })
    return side_nav_items
}