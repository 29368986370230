import { Button, Divider, Image } from 'antd'
import React from 'react'
import firstImage from './../../Images/Documentation/FinanceTrees/1.png'
import secondImage from './../../Images/Documentation/FinanceTrees/2.png'
import thirdImage from './../../Images/Documentation/FinanceTrees/3.png'
import {
    PlusOutlined,
  } from '@ant-design/icons';
import { EditIcon, EyeIcon } from 'lucide-react';
const FinanceTreesDocumentation = () => {
  return (
    <div>
        <div>
            <h3 className='font-bold text-xl'>مقدمة</h3>
            <p>مرحبًا بك في صفحة الدعم الخاصة بلوحة التحكم لإدارة الشجرات المالية. تعد إدارة الشجرات المالية جزءًا أساسيًا من نظامنا المالي، حيث تتيح للمستخدمين تنظيم وتتبع الحسابات المالية بشكل فعّال وسلس. تتكون الشجرة المالية من عنصرين رئيسيين:</p>
            <ul className='my-4 space-y-2 list-disc'>
                <li >
                    <h5 className='font-bold inline ml-2'>اسم الشجرة</h5> يعبر عن هوية الشجرة ويعطيها طابعًا مميزًا يمكن التعرف عليه بسهولة.
                </li>
                <li >
                    <h5 className='font-bold inline ml-2'>تعداد الحسابات في الشجرة</h5> يمثل هذا العنصر الهيكل التفصيلي للحسابات المالية المدرجة ضمن الشجرة، مما يتيح للمستخدمين رؤية شاملة للترتيب الهرمي للحسابات المالية وكيفية تفاعلها مع بعضها البعض.

                </li>
            </ul>
            <p>في هذا القسم، ستجد المعلومات والإرشادات اللازمة لاستخدام لوحة التحكم لإدارة الشجرات المالية بفعالية. سنقدم لك دليلًا شاملاً لإنشاء وإدارة الشجرات، مع التركيز على كيفية تسمية الشجرات بشكل مناسب وتنظيم الحسابات داخلها بطريقة تسهل الوصول إلى المعلومات المالية وتحليلها.</p>
        </div>
        <Divider />
        <div>
            <h3 className='font-bold text-xl'>مكونات الصفحة</h3>
            <p>كما هو موضح في الشكل أدناه، تتكون صفحة إدارة الشجرات المالية من مكونين أساسيين:</p>
            <Image width={600} className='my-4' src={firstImage}/>
            <ul className='mb-4 space-y-2 list-disc'>
                <li >
                    <h5 className='font-bold inline ml-2'>زر إضافة شجرة <Button icon={<PlusOutlined />} type='primary'>إضافة شجرة</Button></h5> يمكن من خلاله إضافة شجرة مالية جديدة. هذا الزر يتيح لك بدء عملية إنشاء شجرة مالية جديدة بسهولة وبخطوات بسيطة.
                </li>
                <li >
                    <h5 className='font-bold inline ml-2'>الجدول الذي يحتوي على معلومات كل شجرة</h5> يعرض هذا الجدول جميع الشجرات المالية الموجودة في النظام مع التفاصيل الخاصة بكل شجرة، ويتضمن:
                </li>
                <li className='px-6 list-none'>
                    <ul className='space-y-2 list-disc'>
                        <li >
                            <h5 className='font-bold inline ml-2'>اسم الشجرة</h5> يعرض الاسم الذي تم اختياره للشجرة.
                        </li>
                        <li >
                            <h5 className='font-bold inline ml-2'>تعداد الحسابات</h5> يعرض عدد الحسابات المدرجة ضمن كل شجرة.
                        </li>
                        <li >
                            <h5 className='font-bold inline ml-2'>أزرار خاصة لكل شجرة</h5> تشمل:
                        </li>
                        <li className='px-6 list-none'>
                            <ul className='space-y-2 list-disc'>
                                <li >
                                    <h5 className='font-bold inline ml-2'>زر الإطلاع <Button type='primary' shape='circle'><EyeIcon size={14} /></Button></h5> لعرض تفاصيل الشجرة ومكوناتها.
                                </li>
                                <li >
                                    <h5 className='font-bold inline ml-2'> زر التعديل <Button  shape='circle'><EditIcon size={14} /></Button></h5> لإجراء تعديلات على الشجرة أو تحديث المعلومات الخاصة بها.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <Divider />
        <div>
            <h3 className='font-bold text-xl mb-4'>كيفية الاستخدام</h3>
            <h5 className='text-md font-bold'>إضافة شجرة جديدة</h5>
            <Image className='my-4' width={400} src={secondImage}/>
            <ul className='space-y-2 list-decimal px-4 '>
                <li>
                    إضغط على زر <Button icon={<PlusOutlined />} type='primary'>إضافة شجرة</Button>
                </li>
                <li>
                    قم بإدخال اسم الشجرة الجديد.
                </li>
                <li>
                احفظ الشجرة لإضافتها إلى النظام.
                </li>
                <li className=' text-red-500 font-bold'>
                    يرجى ملاحظة أنه يمكن تعديل اسم الشجرة ولكن لا يمكن حذف الشجرة بمجرد إضافتها إلى النظام.
                </li>
            </ul>
            <h5 className='text-md font-bold mt-4'>تعديل الشجرة</h5>
            <Image className='my-4' width={400} src={thirdImage}/>
            <ul className='space-y-2 list-decimal px-4 '>
                <li>
                    إضغط على زر <Button className='mx-2' shape='circle'><EditIcon size={14} /></Button> الخاص بكل شجرة في الجدول
                </li>
                <li>
                    قم بتعديل اسم الشجرة الجديد.
                </li>
                <li>
                    إضغط تعديل لحفظ اسم الشجرة الجديد
                </li>
                <li className=' text-red-500 font-bold'>
                    يرجى ملاحظة أنه يمكن تعديل اسم الشجرة ولكن لا يمكن حذف الشجرة بمجرد إضافتها إلى النظام.
                </li>
            </ul>
        </div>
    </div>
  )
}

export default FinanceTreesDocumentation