export const UserGqlSCHEMA = `
MainInfo{
    FirstName
    LastName
}
Credential{
    Email
}
Permissions{
    FinancialSystem
    Warehouses
    Customers
    Suppliers
    Employees
}
LastVisit
`