import React, { useState } from 'react'
import SignInString from './../../Images/SignInStrings.png'
import { Button, Input } from 'antd'
import { DownloadCloud, LogInIcon, MailWarningIcon } from 'lucide-react'
import axios from 'axios'
import { setAuthentication } from '../../auth'
import logo from './../../Images/logo.png'
import { API_URL } from '../../ENV'
const AUTH_URL = API_URL + 'auth/login'

const SignIn = ({signInHandler}) => {
    const [username,SetUsername] = useState('')
    const [password,SetPassword] = useState('')
    const [isError,SetIsError] = useState(false)
    const [errorMsg,setErrorMsg] = useState('')
    const HandleSignInBTN = async() =>{
        if(username !== '' && password !== ''){
            console.log(`G`)
            try {

                await axios.post(AUTH_URL,{
                    Email:username,
                    Password:password
                })
                .then(res => {
                    if(res.status  === 200){
                        setAuthentication(res.data.accessToken)
                        signInHandler()
                    }
                    else{
                        SetIsError(true)
                    }
                })
            }
            catch(err){
                SetIsError(true)
                setErrorMsg('Authentication Failed')

            }
        }
        else{
            SetIsError(true)
            setErrorMsg('Both the username and password must be filled in.')
        }
        
    }

  return (
    <main className='w-full grid grid-cols-4 gap-2 h-[100vh]'>
        <img className=' absolute w-20 top-2 right-2' src={logo}/>
        <div className='w-full h-full flex justify-center items-center relative xl:col-span-2 md:col-span-1'>
            <img className=' absolute w-1/3  top-center left-center' src={SignInString} />
            <h1 className='text-4xl font-bold tracking-wider uppercase text-blue-800'>Ul Dash <br /> <span className='text-3xl text-blue-500'>Next Generation</span></h1>
        </div>
        <div className='flex flex-col justify-center px-20 gap-4 xl:col-span-2 md:col-span-3'>
            <h2 className='font-semibold  px-1'>تسجيل الدخول في لوحة تحكم طريق الحرير</h2>
            <Input value={username} onChange={(e)=>SetUsername(e.target.value)} className='xl:w-2/3 lg:w-full py-2' placeholder='البريد الالكتروني' />
            <Input value={password} onChange={(e)=>SetPassword(e.target.value)} className='xl:w-2/3 lg:w-full py-2' type='password' placeholder='كلمة المرور' />
            <Button onClick={()=>HandleSignInBTN()} type="primary" className='xl:w-1/3 lg:w-1/2 px-2 rotate-180' icon={<LogInIcon />} size={`large`}></Button>
            {
                isError && <p className='text-red-500 text-sm font-semibold flex flex-row items-center'><MailWarningIcon className='mx-2'/>{errorMsg}</p>
            }
        </div>
    </main>
  )
}

export default SignIn