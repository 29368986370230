import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AddNewTreeItemHandler, dltTreeItemHandler, EditTreeItemHandler, GetFinanceTreeById, GetTreeItems } from '../../utils/Api/Finance'
import Header from '../../Components/Header'
import { AutoComplete, Button, Drawer, Empty, Input, message, Modal, Popconfirm, Spin, Tooltip ,Tree} from 'antd'
import {
    PlusOutlined,
    CloseSquareFilled
  } from '@ant-design/icons';
import { EditIcon, InfoIcon, MinimizeIcon, TrashIcon } from 'lucide-react'
import AddNewTreeItem from './AddNewTreeItem'
import EditTreeItem from './EditTreeItem'
import FinanceTreeDocumentation from './FinanceTreeDocumentation'
const {TreeNode} = Tree
const FinanceTree = ({showNothing,showAddModal,showEditModal,addToMinimizeList,showDocumentation}) => {

  

      const [treeData,setTreeData] = useState([])

      const renderTreeNodes = (data) =>
        data.map((item) => {
          if (item.children) {
            return (
              <TreeNode
               className='mb-2 '
                title={
                  <span className='font-bold'>
                    {item.title}
                    <Link to={`/Finance-Tree/${id}/Edit/${item.value}`}>
                      <Button
                          className='mr-4'
                          shape='circle'
                          onClick={() => {}}
                          icon={<EditIcon size={14} />}
                        />
                      </Link>
                 
                   
                  </span>
                }
                key={item.key}
              >
                {renderTreeNodes(item.children)}
              </TreeNode>
            );
          }
          return (
            <TreeNode
            className='mb-2'

              title={
                <span>
                  {item.title}
                  <Link to={`/Finance-Tree/${id}/Edit/${item.value}`}>
                  <Button
                      className='mr-4'
                      shape='circle'
                      onClick={() => {}}
                      icon={<EditIcon size={14} />}
                    />
                  </Link>
                  
                   
                </span>
              }
              key={item.key}
            />
          );
        });

        
    const navigate = useNavigate()

    const {id} = useParams()
    const {acc_id} = useParams()

    const [resetItemHanlder,setResetItemHandler] = useState(false)    
    const [tree,setTree] = useState(null)
    const [isLoading,setIsLoading] = useState(true)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [selectedAccount,setSelectedAccount] = useState(null)
    const [searchText, setSearchText] = useState("");
    const [options, setOptions] = useState([]);

    const [treeItems,setTreeItems] = useState([])
    const [showDocDrawer,setShowDocDrawer] = useState(false)

    const GetSingleTree = async() =>{
        const fetch_tree = await GetFinanceTreeById(id)
        setTree(fetch_tree)
    }   


    const handleSearch = (value) => {
      if (value) {
        const filteredOptions = treeItems &&  treeItems
          .filter(item =>
            item.ItemInfo.AccountNumber.includes(value) ||
            item.ItemInfo.AccountName.includes(value) ||
            item.ItemInfo.AccountType.includes(value)
          )
          .map(item => ({
            value: `${item.ItemInfo.AccountNumber} - ${item.ItemInfo.AccountName} - ${item.ItemInfo.AccountType}`,
            object: item,
          }));
        setOptions(filteredOptions);
      } else {
        setOptions([]);
      }
    };
    const handleSelect =(value, option) => {
      console.log(option.object)
      const selectedObj = treeItems.find(item =>
       item.id === option.object.id
      );
      navigate(`/Finance-Tree/${id}/Edit/${selectedObj.id}`)
    };


    const Minimze = () =>{
        let path = `/Finance-Tree${!isModalOpen ? '/'+ id : !isEdit ? '/'+id+'/Add' : '/'+id+'/Edit/'+acc_id}`
        addToMinimizeList(path,!isEdit ?"إضافة حساب": "تعديل حساب : "+ selectedAccount.ItemInfo.AccountName)
        navigate('/Finance-Tree/'+id)
    }

    const handleCancel = () =>{
        setIsModalOpen(false)
        setResetItemHandler(perv=>!perv)
        navigate('/Finance-Tree/'+id)
    }

    const editTreeItemFunc = async(accountName,accountType,accountNumber) =>{
        if(await EditTreeItemHandler(acc_id,accountNumber,accountName,accountType)){
          message.success('تم تعديل الحساب بنجاح');
          navigate('/Finance-Tree/'+id)
        }
    }

    const dltTreeItemFunc = async(id) =>{ 
        if(await dltTreeItemHandler(id)){
          message.success('تم حذف الحساب بنجاح');
          fetchTreeItems()
        }
    }

    const addNewTreeItemFunc = async(accountName,accountType,accountNumber,itemType,selectedItem) => {
        if(await AddNewTreeItemHandler(id,accountName,accountType,accountNumber,itemType,selectedItem)){
            message.success('تم إضافة الحساب بنجاح');
            navigate('/Finance-Tree/'+id);
            setIsModalOpen(false); 
    
        }
    }   

    const fetchTreeItemById = async (tree_items,id) =>{
        const obj = tree_items.find(item => item.id === id);
        console.log(tree_items,obj)
        setSelectedAccount(obj)
    }

    const onDrawClose = () =>{
      setShowDocDrawer(false)
      navigate('/Finance-Tree/'+id);
    }

    const fetchTreeItems = async() => {
        setIsLoading(true)
        const tree_items = await GetTreeItems(id)
        if(showEditModal){
          fetchTreeItemById(tree_items,acc_id)
        }
        setTreeItems(tree_items)
        const itemsMap = new Map(tree_items.map(item => [item.id, item]));
        const buildTreeNode = (item, visited) => {
      
            const children = (item.ItemConfig.ParentOf || [])
              .map(child => {
                const childItem = itemsMap.get(child.id);
                return buildTreeNode(childItem, visited);
              })
              .filter(child => child !== null); // Exclude null children
            
            return {
              title: item.ItemInfo.AccountName,
              value: item.id,
              key: item.id,
              children,
            };
          }
          const treeDataList = tree_items
      .filter(item => item.ItemConfig.ItemType === 'Starter' || item.ItemConfig.ItemType === 'Single')
      .map(starter => buildTreeNode(starter, new Set()))
      setTreeData(treeDataList)
        setIsLoading(false)
    }

    useEffect(()=>{
        GetSingleTree()
        fetchTreeItems()
        if(!showNothing){
            setIsModalOpen(true)
            if(showAddModal){
                setIsEdit(false)
            }
            if(showEditModal){
                setIsEdit(true)
              
            }
        }
        else{
            setIsModalOpen(false)
        }
    },[id,showNothing,showAddModal,showEditModal])

    useEffect(()=>{
      if(showDocumentation){
        setShowDocDrawer(true)
      }
    },[showDocumentation])

    return (
        <div>
            <Header title={`شجرة ${tree && tree.Name}`} />
            <div className='flex flex-row justify-between'>
              <div>

              <Link to={`/Finance-Tree/${id}/Add`}>
                    <Button icon={<PlusOutlined />} type='primary'>
                        إضافة حساب 
                    </Button>

                   
                </Link>
                <AutoComplete
                        className='mx-4'
                        style={{ width: 300 }}
                        options={options}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        allowClear={{
                          clearIcon: <CloseSquareFilled />,
                        }}
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                        placeholder="البحث السريع ..."
                      >
                        <Input />
                      </AutoComplete>
              </div>
                
                {
                    isLoading ? <Spin className=' animate-pulse' /> : <div className='flex flex-row gap-4 items-center'>  <h5>عدد الحسابات <span className='font-extrabold text-blue-500'>{tree && tree.Count}</span></h5> <Link to={`/Finance-Tree/${id}/Documentation`}><Tooltip title={`التعليمات`}><Button shape='circle' icon={<InfoIcon size={14} />}/></Tooltip></Link></div>
                }
                
             </div>
             <Modal width={800} footer={<></>} title={<div className='flex flex-row items-center gap-2'>
            <Tooltip title="Minimize">
            <Button onClick={()=>Minimze()} shape='circle' size='small'>
                    <MinimizeIcon size={14} />
                </Button>
            </Tooltip>
               {
                !isEdit ? <h5>إضافة حساب جديد</h5> : <h5>تعديل حساب</h5>
               }
                
            </div>} open={isModalOpen}  onCancel={handleCancel}>
            {
                isModalOpen && !isEdit ? <AddNewTreeItem resetItemHanlder={resetItemHanlder} treeItems={treeItems} addNewTreeItem={addNewTreeItemFunc} /> : <EditTreeItem tree={selectedAccount} editTree={editTreeItemFunc}/>
            }
            
        </Modal>
        {
            treeData.length > 0 ? <Tree className='mt-3 p-2'>{renderTreeNodes(treeData)}</Tree> : <div className='bg-white py-8 rounded-md mt-4 shadow-md'><Empty description="لا يوجد بيانات"/></div>
        }

          <Drawer
            title={<Header title={`تعليمات إدارة تفاصيل الشجرات`} />}
            placement={`left`}
            closable={true}
            width={800}
            onClose={onDrawClose}
            open={showDocDrawer}
          >
            <FinanceTreeDocumentation />
          </Drawer>
        
        </div>
    )
}

export default FinanceTree