import axios from "axios"
import { API_URL } from "../../ENV"
import { FinanceTreeGqlSCHEMA, TreeItemGqlSCHEMA } from "../GqlModels/Finance"
import { getAuthentication } from "../../auth"

export const GetFinanceTree = async() =>{
    const res = await axios.post(API_URL+ 'Finance',{
        query:`
            query{
                GetFinanceTree{
                    ${FinanceTreeGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token: `Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetFinanceTree){
        return res.data.data.GetFinanceTree
    }
}

export const AddNewTreeHandler = async (name) =>{
    const res = await axios.post(API_URL+ 'Finance',{
        query:`
            mutation{
                AddNewTree(name:"${name}"){
                    ${FinanceTreeGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token: `Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.AddNewTree){
        return true
    }

}

export const EditTreeHandler = async (id,name) => {
    const res = await axios.post(API_URL+'Finance',{
        query:`
            mutation{
                EditTree(id:"${id}",name:"${name}"){
                    ${FinanceTreeGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.EditTree){
        return true
    }
}

export const DeleteTreeHandler = async (id) => {
    const res = await axios.post(API_URL+'Finance',{
        query:`
            mutation{
                DeleteTree(id:"${id}")
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.DeleteTree){
        return true
    }
}

export const GetFinanceTreeById = async(id) =>{
    const res = await axios.post(API_URL+ 'Finance',{
        query:`
            query{
                GetFinanceTreeById(id:"${id}"){
                    ${FinanceTreeGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    
    if(res.data.data.GetFinanceTreeById){
        return res.data.data.GetFinanceTreeById
    }
}

export const GetTreeItems = async (id) => {
    const res = await axios.post(API_URL+'Finance',{
        query:`
            query{
                GetTreeItems(id:"${id}"){
                    ${TreeItemGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetTreeItems){
        return res.data.data.GetTreeItems
    }
}

export const AddNewTreeItemHandler = async (id,accountName,accountType,accountNumber,itemType,selectedItem) => {
    const res = await axios.post(API_URL+'Finance',{
        query:`
            mutation{
                AddNewTreeItem(financetree:"${id}",itemtype:"${itemType}",relatedto:"${selectedItem}",accountnumber:"${accountNumber}",accountname:"${accountName}",accounttype:"${accountType}"){
                    ${TreeItemGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.AddNewTreeItem){
        return true
    }
}

export const dltTreeItemHandler = async (id) => {
    const res = await axios.post(API_URL+'Finance',{
        query:`
            mutation{
                DeleteTreeItem(id:"${id}")
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.DeleteTreeItem){
        return true
    }
}

export const EditTreeItemHandler = async (id,accountnumber,accountname,accounttype) => {
    const res = await axios.post(API_URL + 'Finance',{
        query:`
            mutation{
                EditTreeItem(id:"${id}",accountnumber:"${accountnumber}",accountname:"${accountname}",accounttype:"${accounttype}"){
                    ${TreeItemGqlSCHEMA}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.EditTreeItem){
        return true
    }
}