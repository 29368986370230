import { Button, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';

const AddNewTreeItem = ({ treeItems, addNewTreeItem, resetItemHanlder }) => {
  const [treesItems, setTreesItems] = useState([]);

  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const [itemType, setItemType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [err,setErr] = useState(false)

  const handleSubmit = () => {
    if (accountName !== '' && accountType !== '' && accountNumber !== '') {
      if (itemType !== null) {
        if (itemType === 'Final' || itemType === 'Related') {
          if (selectedItem !== null) {
            if (!doesAccountNumberExist(accountNumber)) {
              resetInputs();
              addNewTreeItem(accountName, accountType, accountNumber, itemType, selectedItem);
            } else {
              message.error('رقم الحساب موجود بالفعل');
            }
          } else {
            message.error('يجب تحديد العنصر التابع');
          }
        } else {
          if (!doesAccountNumberExist(accountNumber)) {
            resetInputs();
            addNewTreeItem(accountName, accountType, accountNumber, itemType, '');
          } else {
            message.error('رقم الحساب موجود بالفعل');
          }
        }
      } else {
        message.error('يجب تحديد نوع العنصر');
      }
    } else {
      message.error('يجب ملئ كل من : اسم الحساب ,نوع الحساب ورقم الحساب');
    }
  }


  const doesAccountNumberExist = (accountNumber) => {
    return treeItems.some(item => item.ItemInfo.AccountNumber === accountNumber);
  };

  const resetInputs = () => {
    setAccountName('')
    setAccountType('')
    setAccountNumber('')
    setSelectedItem(null)
    setItemType(null)
  }

  useEffect(()=>{
    resetInputs()
  },[resetItemHanlder])

  useEffect(() => {
    const itemsMap = new Map(treeItems.map(item => [item.id, item]));

    const buildTreeNode = (item, visited) => {
      if (item.ItemConfig.ItemType === 'Final' || item.ItemConfig.ItemType === 'Single') {
        return null;
      }

      if (visited.has(item.id)) {
        return null; // Prevent circular references
      }

      visited.add(item.id);

      const children = (item.ItemConfig.ParentOf || [])
        .map(child => {
          const childItem = itemsMap.get(child.id);
          return buildTreeNode(childItem, visited);
        })
        .filter(child => child !== null); // Exclude null children

      visited.delete(item.id);

      return {
        title: item.ItemInfo.AccountNumber+ ' - '+item.ItemInfo.AccountName,
        value: item.id,
        key: item.id,
        children,
      };
    };

    const treeData = treeItems
      .filter(item => item.ItemConfig.ItemType === 'Starter')
      .map(starter => buildTreeNode(starter, new Set()))
      .filter(node => node !== null); // Exclude null nodes

    setTreesItems(treeData);
  }, [treeItems]);

  return (
    <div className='py-4'>
      <div className='grid grid-cols-2 gap-4 mb-8'>
        <div className='flex flex-col space-y-3'>
          <Input value={accountName} onChange={(e) => setAccountName(e.target.value)} placeholder='اسم الحساب' />
          <Input value={accountType} onChange={(e) => setAccountType(e.target.value)} placeholder='نوع الحساب' />
          <Input value={accountNumber} onKeyUp={(e)=>setErr(doesAccountNumberExist(e.target.value))} onChange={(e) => setAccountNumber(e.target.value)} placeholder='رقم الحساب' />
          {err && <p className='text-red-500'>رقم الحساب موجود بالفعل</p>}
        </div>
        <div>
          <Select
            value={itemType}
            onChange={(value) => setItemType(value)}
            className='w-full'
            placeholder="اختر نوع العنصر"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={[
              { value: 'Starter', label: 'عنصر بداية' },
              { value: 'Related', label: 'عنصر مرتبط' },
              { value: 'Final', label: 'عنصر نهائي' },
              { value: 'Single', label: 'عنصر وحيد' },
            ]}
          />
          {(itemType === 'Related' || itemType === 'Final') && (
            <TreeSelect
              showSearch
              className='mt-3'
              style={{
                width: '100%',
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              placeholder="اختر العنصر التابع له"
              allowClear
              treeData={treesItems}
              onChange={(value) => setSelectedItem(value)}
            />
          )}
        </div>
      </div>
      <Button onClick={handleSubmit} type='primary'>إضافة حساب جديد</Button>
    </div>
  );
};

export default AddNewTreeItem;
