import React from 'react'
import firstImage from './../../Images/Documentation/FinanceTree/1.png'
import secondImage from './../../Images/Documentation/FinanceTree/2.png'
import thirdImage from './../../Images/Documentation/FinanceTree/3.png'
import { Button, Divider, Image } from 'antd'
import {
    PlusOutlined,
  } from '@ant-design/icons';
import { EditIcon } from 'lucide-react'
const FinanceTreeDocumentation = () => {
  return (
    <div>
        <div>
            <h3 className='font-bold text-xl'>مقدمة</h3>
            <p>مرحبًا بك في صفحة الدعم الخاصة بتفاصيل الشجرة المالية. تتألف كل شجرة مالية من مجموعة حسابات متنوعة، حيث يتم تنظيم كل حساب وفقًا لأربع مكونات أساسية:</p>
            <ul className='my-4 space-y-2 list-disc'>
                <li >
                    <h5 className='font-bold inline ml-2'>اسم الحساب</h5> يحدد هوية الحساب داخل الشجرة المالية.
                </li>
                <li >
                    <h5 className='font-bold inline ml-2'>نوع الحساب</h5> يوضح الطبيعة الرئيسية للحساب (مثلاً، قائمة المركز المالي، قائمة الدخل، الإيرادات).
                </li>
                <li >
                    <h5 className='font-bold inline ml-2'>رقم الحساب</h5> يعبر عن الرقم التعريفي للحساب في النظام المالي.
                </li>
                <li>
                    <h5 className='font-bold inline ml-2'>نوع العنصر</h5>
                </li>
                <li className='list-none px-6'>
                    <ul className='my-1 space-y-2 list-disc'>
                        <li >
                            <h5 className='font-bold inline ml-2'>عنصر بداية</h5> يمثل بداية تسلسل العمليات المالية.
                        </li>
                        <li >
                            <h5 className='font-bold inline ml-2'>عنصر مرتبط</h5> يمكن ربطه بعناصر أخرى (بداية أو مرتبط).
                        </li>
                        <li >
                            <h5 className='font-bold inline ml-2'>عنصر نهائي</h5> له تبعية لعنصر بداية أو عنصر مرتبط ولا يمكن ربط عنصر تحته.
                        </li>
                        <li >
                            <h5 className='font-bold inline ml-2'>عنصر وحيد</h5> يمثل عنصرًا يمتلك تبعية ولا يمكن ربط عنصر تحته.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <Divider />
        <div>
            <h3 className='font-bold text-xl'>مكونات الصفحة</h3>
            <p>كما هو موضح في الشكل أدناه, تتكون هذه الصفحة من عدة عناصر تساعدك في إدارة وتنظيم الحسابات ضمن الشجرة المالية بشكل فعّال:</p>
            <Image width={600} className='my-4' src={firstImage}/>
            <ul className='mb-4 space-y-2 list-disc'>
                <li >
                    <h5 className='font-bold inline ml-2'>زر إضافة حساب <Button icon={<PlusOutlined />} type='primary'>إضافة حساب</Button></h5> عند الضغط على هذا الزر، ستفتح نافذة تمكنك من إضافة حساب جديد إلى الشجرة المالية. يمكنك تحديد نوع الحساب وإدخال التفاصيل اللازمة.
                </li>
                <li >
                    <h5 className='font-bold inline ml-2'>البحث السريع</h5>يتيح لك هذا الخيار البحث السريع ضمن الحسابات التابعة للشجرة بدلاً من الاعتماد على الشجرة المنسدلة. <br />  يمكنك البحث باستخدام ثلاث مكونات رئيسية: <strong>اسم الحساب</strong> ، <strong>رقم الحساب</strong> ، <strong>ونوع الحساب</strong> . هذا يسهل عملية العثور على الحسابات بسرعة وفعالية.
                </li>
                <li>
                    <h5 className='font-bold inline ml-2'>عدد الحسابات:</h5>يظهر هذا العنصر عدد الحسابات التي تتبع للشجرة المالية. يوفر لك نظرة عامة سريعة حول عدد الحسابات المدرجة في الشجرة.
                </li>
                <li>
                    <h5 className='font-bold inline ml-2'>الشجرة المنسدلة للحساب:</h5>يعرض هذا الجزء ترتيب وتبعية الحسابات لبعضها البعض بناءً على نوع العنصر. يساعدك هذا العرض على فهم الهيكل التنظيمي للحسابات داخل الشجرة والعلاقات بينها.
                </li>
            </ul>
        </div>
        <Divider />
        <div>
            <h3 className='font-bold text-xl mb-4'>كيفية الاستخدام</h3>
            <h5 className='text-md font-bold'>إضافة حساب</h5>
            <Image className='my-4' width={400} src={secondImage}/>
            <ul className='space-y-2 list-decimal px-4 '>
                <li>
                    إضغط على زر <Button icon={<PlusOutlined />} type='primary'>إضافة حساب</Button>
                </li>
                <li>
                    قم بإدخال كل من <strong>اسم الحساب</strong>, <strong>نوع الحساب</strong> و<strong>رقم الحساب</strong>
                </li>
                <li>
                    قم بتحديد نوع العنصر 
                </li>
                <li className='list-none'>
                    <ul className='list-disc'>
                        <li>
                            في حال كان نوع العنصر <strong>بداية</strong> أو <strong>وحيد</strong> لا يتعين عليك تحديد العنصر المرتبط به
                        </li>
                        <li>
                            في حال كان نوع العنصر <strong>مرتبط</strong> أو <strong>نهائي</strong> يتعين عليك تحديد العنصر المرتبط به
                        </li>
                    </ul>
                </li>
                <li>
                    بعد ملئ جميع البيانات اللازمة قم بالضغط على <Button type='primary'>إضافة حساب جديد</Button>
                </li>
                <li className=' text-red-500'>
                يرجى ملاحظة أنه يمكن تعديل كل من <strong>اسم الحساب</strong>, <strong>نوع الحساب</strong> و<strong>رقم الحساب</strong> فقط دون القدرة على تعديل نوع العنصر والعنصر المرتبط بالإضافة أنه لا يمكن حذف الحساب أيضا.
                </li>
            </ul>
            <br /><br />
            <h5 className='text-md font-bold'>تعديل حساب</h5>
            <Image className='my-4' width={400} src={thirdImage}/>
            <ul className='space-y-2 list-decimal px-4 '>
                <li>
                إضغط على زر <Button className='mx-2' shape='circle'><EditIcon size={14} /></Button> الخاص بكل حساب في الشجرة المنسدلة
                </li>
                <li>
                    قم بتعديل كل من <strong>اسم الحساب</strong>, <strong>نوع الحساب</strong> و<strong>رقم الحساب</strong>
                </li>
                
                <li>
                    بعد تعديل البيانات اللازمة قم بالضغط على <Button type='primary'>تعديل</Button>
                </li>
                <li className=' text-red-500'>
                يرجى ملاحظة أنه يمكن تعديل كل من <strong>اسم الحساب</strong>, <strong>نوع الحساب</strong> و<strong>رقم الحساب</strong> فقط دون القدرة على تعديل نوع العنصر والعنصر المرتبط بالإضافة أنه لا يمكن حذف الحساب أيضا.
                </li>
            </ul>
        </div>
        
    </div>
  )
}

export default FinanceTreeDocumentation