import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'

const EditTree = ({tree,editTree}) => {
    const [name,setName] = useState('')

    const handleSubmit = () =>{
        editTree(name)
    }

    useEffect(()=>{
        if(tree){
            setName(tree.Name)
        }
    },[tree])

    return (
        <div className='flex flex-col space-y-3 py-3'>
                <Input value={name} onChange={(e)=>setName(e.target.value)} size='large' placeholder='اسم الشجرة' />
                <Button onClick={handleSubmit} type='primary'>تعديل</Button>
        </div>
    )
}

export default EditTree