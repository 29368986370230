import { Button, Input } from 'antd'
import React, { useState } from 'react'

const AddNewTree = ({addNewTree}) => {
    const [name,setName] = useState('')

    const handleSubmit = () => {
        setName('')
        addNewTree(name)
    }

    return (
        <div className='flex flex-col space-y-3 py-3'>
            <Input value={name} onChange={(e)=>setName(e.target.value)} size='large' placeholder='اسم الشجرة' />
            <Button onClick={handleSubmit} type='primary'>إضافة</Button>
        </div>
    )
}

export default AddNewTree