import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';

const findLinkByKey = (key, items) => {
  for (const item of items) {
    if (item.key === key) {
      return item.link;
    }
    if (item.children) {
      const link = findLinkByKey(key, item.children);
      if (link) {
        return link;
      }
    }
  }
  return null;
};

const SideNav = ({ collapsed, items }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(items);
  }, [items]);

  const handleClick = (e) => {
    const link = findLinkByKey(e.key, data);
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className='w-full'>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        className='bg-transparent'
        inlineCollapsed={collapsed}
        items={data}
        onClick={handleClick}
      />
    </div>
  );
};

export default SideNav;
