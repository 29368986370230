import React from 'react';
import { Breadcrumb } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { HomeIcon } from 'lucide-react';

const translationMap = {
  'Documentation': 'صفحة الدعم',
  'Finance-Tree':'الشجرة المالية',
  'Finance-Trees':'إدارة الشجرات',
  'Add': 'إضافة',
  'Edit':'تعديل',
  'My-Account':'حسابي'
};

const DynamicBreadcrumb = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  
  const breadcrumbItems = [
    {
      title: <Link to="/"><span className='flex flex-row items-center justify-center gap-1'><HomeIcon size={15} />الرئيسية</span></Link>,
      key: 'home',
    },
    ...pathSnippets.map((snippet, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const translatedSnippet = translationMap[snippet] || snippet.replace('-', ' ');
      return {
        title: <Link to={url}>{translatedSnippet}</Link>,
        key: url,
      };
    }),
  ];

  return (
    <Breadcrumb separator=">" items={breadcrumbItems} />
  );
};

export default DynamicBreadcrumb;
