export const FinanceTreeGqlSCHEMA = `
id
Name
Count
`
export const TreeItemGqlSCHEMA = `
id
FinanceTree{
    ${FinanceTreeGqlSCHEMA}
}
ItemConfig{
    ItemType
    RelatedTo{
        id
        ItemInfo{
            AccountName
        }
    }
    ParentOf{
        id
        ItemInfo{
            AccountName
        }
    }
}
ItemInfo{
    AccountNumber
    AccountName
    AccountType
}
`
