import axios from "axios"
import { API_URL } from "../../ENV"
import { UserGqlSCHEMA } from "../GqlModels/User"
import { getAuthentication } from "../../auth"

export const GetUserPermissions = async() =>{
    const res = await axios.post(API_URL+'Authenticate',{
        query:`{
            GetUserPermissions{
                ${UserGqlSCHEMA}
            }
        }`
    },{
        headers:{
            token: `Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetUserPermissions){
        return res.data.data.GetUserPermissions
    }
}