import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Button, Drawer, message, Modal, Popconfirm, Spin, Table, Tooltip } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  PlusOutlined,
} from '@ant-design/icons';
import { EditIcon, EyeIcon, InfoIcon, MinimizeIcon, TrashIcon } from 'lucide-react';
import AddNewTree from './AddNewTree';
import EditTree from './EditTree';
import { AddNewTreeHandler, DeleteTreeHandler, EditTreeHandler, GetFinanceTree, GetFinanceTreeById } from '../../utils/Api/Finance';
import FinanceTreesDocumentation from './FinanceTreesDocumentation';


const FinanceTress = ({showNothing,showAddModal,showEditModal,addToMinimizeList,fetchSideNav,showDocumentation}) => {
  const table_columns = [
    {
        title:"اسم الشجرة",
        dataIndex:"name",
        key:"name",
        render:(text)=><h6 className='font-bold'>{text}</h6>
    },
    {
        title:"تعداد الحسابات",
        dataIndex:"count",
        key:"count",
        render:(text)=><h6 className='font-bold'>{text}</h6>
    },
    {
        title:"",
        dataIndex:"id",
        key:"id",
        render:(id)=><div className='flex flex-row gap-3'>
            <Link to={"/Finance-Tree/"+id}><Button type='primary' shape='circle'><EyeIcon size={14} /></Button></Link>
            <Link to={"/Finance-Trees/Edit/"+id}><Button shape='circle'><EditIcon size={14} /></Button></Link>
          
        </div>
    }
  ]
  const navigate = useNavigate()

  const [isLoading,setIsLoading] = useState(true)
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [isEdit,setIsEdit] = useState(false)
  const {id} = useParams()
  const [selectedTree,setSelectedTree] = useState(null)
  const [table_content,setTableContent] = useState([])
  const [showDocDrawer,setShowDocDrawer] = useState(false)
  const Minimze = () =>{
    let path = `/Finance-Trees${!isModalOpen ? '' : !isEdit ? '/Add' : '/Edit/'+id}`
    addToMinimizeList(path,!isEdit ?"إضافة شجرة": "تعديل شجرة : "+ selectedTree.Name)
    
    navigate('/Finance-Trees')
  }

  const onDrawClose = () =>{
    setShowDocDrawer(false)
    navigate('/Finance-Trees')

  }
  const handleOk = () =>{

  }

  const handleCancel = () =>{
    setIsModalOpen(false)
    navigate('/Finance-Trees')
  }

  const addNewTreeFunc = async(name) =>{
      if(await AddNewTreeHandler(name)){
        message.success('تم إضافة الشجرة المالية بنجاح');
        await fetchSideNav().then(()=>{
          setTimeout(() => {
            navigate('/Finance-Trees');
            setIsModalOpen(false); // Close the modal after adding
          }, 5000);
        })
        
      }
  }

  const editTreeFunc = async(name) =>{
      if(await EditTreeHandler(selectedTree.id,name)){
        message.success('تم تعديل الشجرة المالية بنجاح');
        fetchSideNav();
        await fetchSideNav().then(()=>{
          setTimeout(() => {
            navigate('/Finance-Trees');
            setIsModalOpen(false); // Close the modal after adding
          }, 280);
        })
      }
  }

  const fetchData = async() => {
    const fetched_trees = await GetFinanceTree()
    let dataSource = []
    fetched_trees.map((ra)=>dataSource.push({
      key:ra.id,
      name:ra.Name,
      id:ra.id,
      count:ra.Count,
    }))
    setTableContent(dataSource)
    setIsLoading(false)
  } 

  const fetchSingleData = async() =>{
    const fetch_tree = await GetFinanceTreeById(id)
    setSelectedTree(fetch_tree)
  }

  useEffect(()=>{
    fetchData()
    if(showAddModal || showEditModal){
      setIsModalOpen(true)
      if(showAddModal){
        setIsEdit(false)
      }
      if(showEditModal){
        setIsEdit(true)
        fetchSingleData()
      }
    }
    if(showNothing){
      setIsModalOpen(false)
    }
  },[showAddModal,showEditModal])


  useEffect(()=>{
    if(showDocumentation){
        setShowDocDrawer(true)
    }
  },[showDocumentation])
  return (
    <div>
      <Header title={`إدارة الشجرات`} />
      <div className='flex flex-row justify-between'>
                <Link to={`/Finance-Trees/Add`}>
                    <Button icon={<PlusOutlined />} type='primary'>
                        إضافة شجرة 
                    </Button>
                </Link>
                {
                    isLoading ? <Spin className=' animate-pulse' /> :<Link to={`/Finance-Trees/Documentation`}><Tooltip title={`التعليمات`}><Button shape='circle' icon={<InfoIcon size={14} />}/></Tooltip></Link>
                }
                
        </div>
        <Modal footer={<></>} title={<div className='flex flex-row items-center gap-2'>
            <Tooltip title="Minimize">
            <Button onClick={()=>Minimze()} shape='circle' size='small'>
                    <MinimizeIcon size={14} />
                </Button>
            </Tooltip>
               {
                !isEdit ? <h5>إضافة شجرة جديدة</h5> : <h5>تعديل الشجرة</h5>
               }
                
            </div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {
                !isEdit ? <AddNewTree addNewTree={addNewTreeFunc} /> : <EditTree tree={selectedTree} id={id} editTree={editTreeFunc}/>
            }
            
        </Modal>

        <Table className='mt-5' columns={table_columns} dataSource={table_content} />
        <Drawer
            title={<Header title={`تعليمات إدارة الشجرات`} />}
            placement={`left`}
            closable={true}
            width={800}
            onClose={onDrawClose}
            open={showDocDrawer}
          >
            <FinanceTreesDocumentation />
          </Drawer>
    </div>
  )
}

export default FinanceTress