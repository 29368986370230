import { Divider } from 'antd';
import React from 'react';

const Header = ({ title }) => {
  return (
        
        <Divider  orientation="left">
        <h2 className="text-2xl font-bold text-blue-500">{title}
      </h2>
        </Divider>
    
  );
};

export default Header;
