import logo from './logo.svg';
import './App.css';
import { Button, Spin } from 'antd';
import Main from './Scenes/Main';
import SignIn from './Scenes/SignIn';
import { useEffect, useState } from 'react';
import { getAuthentication } from './auth';
import { GetUserPermissions } from './utils/Api/Authenticate';


function App() {
    const [showSignIn,setShowSignIn] = useState(false)
    const [showDashboard,setShowDashboard] = useState(false)
    const [signedIn,setSignedIn] = useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const [token,setToken] = useState('')
    const [user,setUser] = useState(null)
    const [permissions,setPermissions] = useState([])

    const setUserHandler = async() =>{
      const handle_user = await GetUserPermissions()
      setUser(handle_user)
      setPermissions(handle_user.Permissions)

    }
    useEffect(() => {
        if(getAuthentication() !== null){
          setUserHandler()
          setSignedIn(true)
          setShowDashboard(true)
          setShowSignIn(false)
          setToken(getAuthentication())
        }
        else{
          setSignedIn(false)
          setShowDashboard(false)
          setShowSignIn(true)
        }
        setIsLoading(false)
        
      }, [])

    const handleSignInStatus = async() =>{
        setUserHandler()
        setSignedIn(prev=> !prev)
        setShowDashboard(true)
        setShowSignIn(false)
        setToken(getAuthentication())
    
    }

    return (
        <div className="app bg-gradient-to-l from-slate-100 to-blue-100 ">
            {isLoading && <div className='flex h-[100vh] justify-center items-center'><Spin /></div>  }
            {showSignIn && <SignIn signInHandler={handleSignInStatus} />}
            {showDashboard &&  <Main permissions={permissions} user={user} />}
        </div>
    );
}

export default App;
